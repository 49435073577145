// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apibase: 'https://production-services.bluehawk.ai/',
    // apibase: 'https://uat.bluehawk.ai/services/',
  //  apibase: 'http://staging.bluehawk.ai/services/',
   apibase: 'https://uat.bluehawk.ai/services/',
  //  apibase: 'https://dev.bluehawk.ai/services/',
   //apibase: 'http://localhost:5000/',
   //apibase: 'https://platform.bluehawk.ai/services/',
  //  apibase: 'http://dev.bluehawk.ai/staging-services/',
    //  apibase: 'http://dev.bluehawk.ai/services/',
    //apibase: 'http://demo.bluehawk.ai/services/',
    //apibase: 'https://uatold.bluehawk.ai/services/',
  mapboxAccessToken:
    'pk.eyJ1IjoiYWdhbmpvbyIsImEiOiJjamU2ZTA5MXcxc2ozMzBycWEzNXM4aDAzIn0.axzF_Kq3StzWSmQC8hoccg',
  s3BucketUrlOld: 'http://s3.ap-south-1.amazonaws.com/garudauav-in',
  S3BucketUrl: 'http://s3.ap-south-1.amazonaws.com/bluehawk-in',
  SecureS3BucketUrl: 'https://s3.ap-south-1.amazonaws.com/bluehawk-in',
  baseStreamingUrl: 'rtmp://mediaserver.bluehawk.ai/live/',
  accessKeyOld: 'AKIA2FHVAEMXKJ3N7LVX',
  secretOld: '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',
  test: 'test',
  accessKeyjune17: 'AKIAY74WNG3KR6RT47JJ',
  secretjune17: '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',
  accessKey: 'AKIAY74WNG3KYMNKYSHT',
  secret: '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',
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
