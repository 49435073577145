import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/modules/login/login.service';
import { Router } from '@angular/router'

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  submitted: boolean = false;
  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<ChangePasswordComponent>, private spinner: NgxSpinnerService,private toastr:ToastrService,private loginService:LoginService,private router:Router) { }

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      CurrentPassword: ['', Validators.required],
      NewPassword: ['', Validators.required],
      ConfirmPassword: ['', Validators.required],
    });
  }
  get f() {
    return this.changePasswordForm.controls;
  }
  cancel() {
    this.dialogRef.close()
  }
  onSubmit() {
    this.submitted = true;
    this.spinner.show();
    if (this.changePasswordForm.valid) {
      if (this.changePasswordForm.value.NewPassword != this.changePasswordForm.value.ConfirmPassword) {
        this.spinner.hide();
        this.toastr.error('Password must be same')
        return
      }
      else {
        let userid=localStorage.getItem('id');
        let data={
          UserId:userid,
          CurrentPassword: this.changePasswordForm.value.CurrentPassword,
          NewPassword: this.changePasswordForm.value.NewPassword,
          ConfirmPassword: this.changePasswordForm.value.ConfirmPassword
        }
        this.loginService.changePassword(data).subscribe((result)=>{
          console.log(">>>>resulr",result)
          if(result.IsSuccess){
            this.toastr.success(result.SuccessMessage);
            this.spinner.hide();
            localStorage.clear();
            this.dialogRef.close();
            this.router.navigate(["login"]);
           
          }
          else{
            this.toastr.error(result.ErrorMessage);
            this.spinner.hide();
          }
        })
      }
    }
    else {
      this.spinner.hide();
    }
  }
}
