import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { ActivateGuard } from './guard/activate-guard'
import { DefaultLayoutComponent } from './components/common/default-layout/default-layout.component'

const routes: Routes = [
  //   {
  //   path: '',
  //   redirectTo: 'dashboard',
  //   pathMatch: 'full',
  // },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home',
    },
    children: [
      // {
      //   path: 'dashboard',
      //   loadChildren: () =>
      //     import('./modules/home/home.module').then((m) => m.HomeModule),
      //   canActivate: [ActivateGuard],
      // },
    
      {
        // path: 'dashboard',

        path: '',
        loadChildren: () =>
          import('./modules/projects/projects.module').then(
            (m) => m.ProjectsModule
          ),
        canActivate: [ActivateGuard],
      },
      {
        // path: 'dashboard',

        path: 'dashboard',
        loadChildren: () =>
          import('./modules/dashboard-safety/dashboard-safety.module').then(
            (m) => m.DashboardSafetyModule
          ),
        canActivate: [ActivateGuard],
      },
      {
        // path: 'dashboard',

        path: 'flight-dashboard',
        loadChildren: () =>
          import('./modules/flight-dashboard/flight-dashboard.module').then(
            (m) => m.FlightDashboardModule
          ),
        canActivate: [ActivateGuard],
      },
      {
        // path: 'dashboard',

        path: 'projects',
        loadChildren: () =>
          import('./modules/projects/projects.module').then(
            (m) => m.ProjectsModule
          ),
        canActivate: [ActivateGuard],
      },
      {
        path: 'DataPoolDashboard',
        loadChildren: () =>
          import('./modules/data-pool-dashboard/data-pool-dashboard.module').then(
            (m) => m.DataPoolDashboardModule
          ),
        //component: ProjectsComponent,
        canActivate: [ActivateGuard],
      },
      {
        path: 'live-tracking',
        loadChildren: () =>
          import('./modules/live-tracking/live-tracking.module').then(
            (m) => m.LiveTrackingModule
          ),
        //component: ProjectsComponent,
        canActivate: [ActivateGuard],
      },
      {
        path: 'command-center',
        loadChildren: () =>
          import('./modules/command-center/command-center.module').then(
            (m) => m.CommandCenterModule
          ),
        //component: ProjectsComponent,
        canActivate: [ActivateGuard],
      },
      // {
      //   path: 'camera-feed',
      //   loadChildren: () =>
      //     import('./modules/camera-feed/camera-feed.module').then(
      //       (m) => m.CameraFeedModule
      //     ),
      //   //component: ProjectsComponent,
      //   canActivate: [ActivateGuard],
      // },
      {
        path: 'camera-feed/cameraId/:id',
        loadChildren: () =>
          import('./modules/camera-feed/camera-feed.module').then(
            (m) => m.CameraFeedModule
          ),
        //component: ProjectsComponent,
        canActivate: [ActivateGuard],
      },
      // {
      //   path: '',
      //   loadChildren: () =>
      //     import('./modules/projects/projects.module').then(
      //       (m) => m.ProjectsModule
      //     ),
      //   //component: ProjectsComponent,
      //   canActivate: [ActivateGuard],
      // },
      {
        path: 'surveys/:id',
        loadChildren: () =>
          import('./modules/surveys/surveys.module').then(
            (m) => m.SurveysModule
          ),
        canActivate: [ActivateGuard],
      },
      {
        path: 'organizations',
        loadChildren: () =>
          import('./modules/organization/organization.module').then(
            (m) => m.OrganizationModule
          ),
        canActivate: [ActivateGuard],
      },
      {
        path: 'sensors',
        loadChildren: () =>
          import('./modules/sensor/sensor.module').then((m) => m.SensorModule),
        canActivate: [ActivateGuard],
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./modules/user/user.module').then((m) => m.UserModule),
        canActivate: [ActivateGuard],
      },
      {
        path: 'apis',
        loadChildren: () =>
          import('./modules/api/api.module').then((m) => m.ApiModule),
        canActivate: [ActivateGuard],
      },
      {
        path: 'uav',
        loadChildren: () =>
          import('./modules/uav/uav.module').then((m) => m.UavModule),
        canActivate: [ActivateGuard],
      },
      {
        path: 'flight-log',
        loadChildren: () =>
          import('./modules/flight-log/flight-log.module').then(
            (m) => m.FlightLogModule
          ),
        canActivate: [ActivateGuard],
      },
      {
        path: 'schedule-flight',
        loadChildren: () =>
          import('./modules/schedule-flight/schedule-flight.module').then(
            (m) => m.ScheduleFlightModule
          ),
        canActivate: [ActivateGuard],
      },
      {
        path: 'vendors',
        loadChildren: () =>
          import('./modules/vendors/vendors.module').then(
            (m) => m.VendorsModule
          ),
        canActivate: [ActivateGuard],
      },
      {
        path: 'vendors-management',
        loadChildren: () =>
          import('./modules/vendors-management/vendors-management.module').then(
            (m) => m.VendorsManagementModule
          ),
        canActivate: [ActivateGuard],
      },
      {
        path: 'master',
        loadChildren: () =>
          import('./modules/lookup-master/lookup-master.module').then(
            (m) => m.LookupMasterModule
          ),
        //component: ProjectsComponent,
        canActivate: [ActivateGuard],
      },
      {
        path: 'Lookupdetail',
        loadChildren: () =>
          import('./modules/lookup-detail/lookup-detail.module').then(
            (m) => m.LookupDetailModule
          ),
        //component: ProjectsComponent,
        canActivate: [ActivateGuard],
      },
      {
        path: 'anomalies',
        loadChildren: () =>
          import('./modules/anomalies-management/anomalies-management.module').then(
            (m) => m.AnomaliesManagementModule
          ),
        //component: ProjectsComponent,
        canActivate: [ActivateGuard],
      },
    ],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'workspace',
    loadChildren: () =>
      import('./modules/workspace/workspace.module').then(
        (m) => m.WorkspaceModule
      ),
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
