import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ChangePasswordComponent } from '../change-password/change-password.component'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  loggedInuser: any
  isLoaded: boolean = false
  constructor(private dialog:MatDialog) {}

  ngOnInit(): void {
    this.loggedInuser = localStorage['email']
    this.isLoaded = true
  }
  logOutUser() {
    localStorage.clear()
    window.location.reload()
  }
  changePassword(){
    const reportdialog = this.dialog.open(ChangePasswordComponent, {
      width: '800px',
      disableClose: true,
    })
    reportdialog.afterClosed().subscribe((result) => {
    })
  }
}
