<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" color="#0C6FFF" type="line-spin-fade">
</ngx-spinner>
<div class="close_btn" (click)="cancel()"></div>
<div class="page-header" style="display:inline">
  <h5 style="margin-top:-20px;">Change Password</h5>
  <!--<p class="text-center">You can edit your Information here</p>-->
</div>

<form [formGroup]="changePasswordForm">
  <div class="row">
    <div class="col-md-12">
      <fieldset class="form-group">
        <label class="control-label required">
          Current Password
        </label>
        <div class="input-group">
          <div class="input-group-addon"><i class="glyphicon"></i></div>
          <input type="password" class="form-control" name="current password" formControlName="CurrentPassword" placeholder="Current password" />
        </div>
        <div *ngIf="submitted && f.CurrentPassword.errors" class="error-msg">
          <div *ngIf="f.CurrentPassword.errors.required">Current Password is required</div>
        </div>
      </fieldset>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <fieldset class="form-group">
        <label class="control-label required">
          New Password
        </label>
        <div class="input-group">
          <div class="input-group-addon"><i class="glyphicon"></i></div>
          <input type="password" class="form-control" name="new password" formControlName="NewPassword" placeholder="New password" />
        </div>
        <div *ngIf="submitted && f.NewPassword.errors" class="error-msg">
          <div *ngIf="f.NewPassword.errors.required">New Password is required</div>
        </div>
      </fieldset>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <fieldset class="form-group">
        <label class="control-label required">
          Confirm Password
        </label>
        <div class="input-group">
          <div class="input-group-addon"><i class="glyphicon"></i></div>
          <input type="password" class="form-control" name="confirm password" formControlName="ConfirmPassword" placeholder="Confirm password" />
        </div>
        <div *ngIf="submitted && f.ConfirmPassword.errors" class="error-msg">
          <div *ngIf="f.ConfirmPassword.errors.required">Confirm Password is required</div>
        </div>
      </fieldset>
    </div>
  </div>
</form>
  <div class="col-md-12 bottom_btn">
    <button (click)="cancel()" class="btn btn-primary btn-lg cancel_btn">
      Cancel
    </button>
    <button class="btn btn-primary btn-lg save_btn" (click)="onSubmit()">
      Save
    </button>
  </div>
