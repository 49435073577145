import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatDialogModule } from '@angular/material/dialog'
import { MatListModule } from '@angular/material/list'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatButtonModule } from '@angular/material/button'
import { SearchPipe } from '../pipes/search.pipe'
import { MatTreeModule } from '@angular/material/tree'
import { CdkTreeModule } from '@angular/cdk/tree'
import { MatIconModule } from '@angular/material/icon'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatNativeDateModule } from '@angular/material/core'
import { MatRadioModule } from '@angular/material/radio'
import { NgxPaginationModule } from 'ngx-pagination'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown'
import { NgSelectModule } from '@ng-select/ng-select'
import { MatTabsModule } from '@angular/material/tabs'
import { MatDatepickerModule } from '@angular/material/datepicker'
import {MatBadgeModule} from '@angular/material/badge';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatSelectModule} from '@angular/material/select'
import {MatSliderModule} from '@angular/material/slider';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import {MatChipsModule} from '@angular/material/chips';
import {MatMenuModule} from '@angular/material/menu';

@NgModule({
  declarations: [SearchPipe],
  imports: [
    CommonModule,
    MatDialogModule,
    MatListModule,
    MatGridListModule,
    FlexLayoutModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatTreeModule,
    CdkTreeModule,
    MatIconModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatRadioModule,
    NgxPaginationModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    NgMultiSelectDropDownModule,
    NgSelectModule,
    MatTabsModule,
    MatDatepickerModule,
    MatBadgeModule,
    MatTooltipModule,
    MatButtonToggleModule,
    DragDropModule,
    MatSelectModule,
    MatSliderModule,
    NgxSliderModule,
    MatChipsModule,
    MatMenuModule
  ],
  exports: [
    CommonModule,
    MatDialogModule,
    MatListModule,
    MatGridListModule,
    FlexLayoutModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    SearchPipe,
    MatTreeModule,
    CdkTreeModule,
    MatIconModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatRadioModule,
    NgxPaginationModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    NgMultiSelectDropDownModule,
    NgSelectModule,
    MatTabsModule,
    MatDatepickerModule,
    MatBadgeModule,
    MatTooltipModule,
    MatButtonToggleModule,
    DragDropModule,
    MatSelectModule,
    MatSliderModule,
    NgxSliderModule,
    MatChipsModule,
    MatMenuModule
  ],
})
export class MaterialModule {}
