<app-header id="header"></app-header>
<section class="dashboard-home">
    <div class="container-fluid pl-0">
        <div class="row">
            <app-left-panel class="col-md-3 db-sidebar" id="leftpanel"></app-left-panel>
            <div class="col-md-9 db-main-screen">
                <div class="dashboard-main" style="padding-right: 0px;">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>