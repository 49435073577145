/**
 * Created by Harish Chandra.
 */
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router'
import { Injectable } from '@angular/core'
import { LoginService } from '../modules/login/login.service'

// export default class ActivateGuard implements CanActivate
@Injectable()
export class ActivateGuard implements CanActivate {
  private can: boolean = false
  constructor(private router: Router, private access: LoginService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // If block to bypass auth for shared urls
    // if(route.url["0"].path == "workspace"){
    //   return true;
    // }
    if (localStorage.getItem('loginAt') != null) {
      var loginTIme = Number(localStorage.getItem('loginAt'))
      var timeDiff = new Date().getTime() - loginTIme
      var hours = Math.floor(timeDiff / 1000 / 60 / 60)
      if (hours <= 24) {
        if (localStorage.getItem('orgDetails') == null) {
          this.getUserByLoginId()
        } else {
          var profile = JSON.parse(localStorage.getItem('orgDetails'))
          if (profile.Id != localStorage.getItem('id')) {
            this.getUserByLoginId()
          } else {
            setTimeout(() => {
              this.updateLogo(profile)
            }, 500)
            // <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D" width="0" height="0" alt="" />
          }
        }
        return true
      } else {
        this.router.navigate(['/login'], {
          queryParams: { returnUrl: state.url },
        })
        return false
      }
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } })
    return false
  }

  getUserByLoginId() {
    this.access.getUserByLoginId().subscribe(
      (response) => {
        localStorage.setItem('orgDetails', JSON.stringify(response))
        this.updateLogo(response)
      },
      (err) => {
        console.log(err)
      }
    )
  }

  updateLogo(data) {
    var org = data.OrganizationLookup.filter(
      (i) => i.OrganizationId == data.OrganizationId
    )
    if (data.Id == localStorage.getItem('id') && org[0].LogoFile != null) {
      // this.sharedService.updateLogo(org[0].LogoFile);
    }
  }
}
