import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/modules/login/login.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  submitted: boolean = false;
  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<ResetPasswordComponent>, private spinner: NgxSpinnerService,private toastr:ToastrService,private loginService:LoginService,private router:Router, @Inject(MAT_DIALOG_DATA) public dialogData: any) { }

  ngOnInit(): void {
    this.resetPasswordForm = this.formBuilder.group({
      NewPassword: ['', Validators.required],
      ConfirmPassword: ['', Validators.required],
    });
  }
  get f() {
    return this.resetPasswordForm.controls;
  }
  cancel() {
    this.dialogRef.close()
  }
  onSubmit() {
    this.submitted = true;
    this.spinner.show();
    if (this.resetPasswordForm.valid) {
      if (this.resetPasswordForm.value.NewPassword != this.resetPasswordForm.value.ConfirmPassword) {
        this.spinner.hide();
        this.toastr.error('Password must be same')
        return
      }
      else {
        console.log(">>>dialogData",this.dialogData)
        let userid=this.dialogData.Id
        let data={
          UserId:userid,
          NewPassword: this.resetPasswordForm.value.NewPassword,
          ConfirmPassword: this.resetPasswordForm.value.ConfirmPassword
        }
        this.loginService.resetPassword(data).subscribe((result)=>{
          if(result.IsSuccess){
            this.toastr.success(result.SuccessMessage);
            this.spinner.hide();
            localStorage.clear();
            this.dialogRef.close();
          }
          else{
            this.toastr.error(result.ErrorMessage);
            this.spinner.hide();
          }
        })
      }
    }
    else {
      this.spinner.hide();
    }
  }
}
