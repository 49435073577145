import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { Observable } from 'rxjs/Rx'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router'
import { HttpRequestManager } from '../../http-manager/http-manager.service'

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private httpManager: HttpRequestManager) {}
  login(value) {
    return this.httpManager.post(environment['apibase'] + 'api/auth', value)
  }

  getUserByLoginId() {
    return this.httpManager.get(environment['apibase'] + 'api/users/LoginUser')
  }
  changePassword(value){
    return this.httpManager.post(environment['apibase'] + 'api/Users/Change_Password', value)
  }
  resetPassword(value){
    return this.httpManager.post(environment['apibase'] + 'api/Users/Reset_Password', value)
  }
  
}
