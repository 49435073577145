/**
 * Created by Harish Chandra.
 */
// Imports
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient, HttpHeaders } from '@angular/common/http'

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
}

@Injectable({ providedIn: 'root' })
export class HttpRequestManager {
  // Resolve HTTP using the constructor
  constructor(private http: HttpClient) {}

  get(url: string): Observable<any> {
    return this.http.get(url, httpOptions)
  }

  post(url: string, body: Object, customHeader?): Observable<any> {
    return this.http.post(url, body, customHeader ? customHeader : httpOptions);
  }

  put(url: string, body: Object): Observable<any> {
    return this.http.put(url, body, httpOptions)
  }

  delete(url: string): Observable<any> {
    return this.http.delete(url, httpOptions)
  }
}
