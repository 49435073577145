<header class="fixed mainHead">
  <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
    <!-- <div class="menu" onclick="menuBtn()"><img src="/assets/images/menu.svg" alt="img"></div> -->
    <a class="navbar-brand" href="#">BlueHawk</a>
    <ul class="nav navbar-nav ml-auto">
      <li class="nav-item dropdown" dropdown placement="bottom right">
        <a class="nav-link" data-toggle="dropdown"  role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5859 8.89844C11.6016 9.88281 10.4062 10.375 9 10.375C7.59375 10.375 6.39844 9.88281 5.41406 8.89844C4.42969 7.91406 3.9375 6.71875 3.9375 5.3125C3.9375 3.90625 4.42969 2.71094 5.41406 1.72656C6.39844 0.742188 7.59375 0.25 9 0.25C10.4062 0.25 11.6016 0.742188 12.5859 1.72656C13.5703 2.71094 14.0625 3.90625 14.0625 5.3125C14.0625 6.71875 13.5703 7.91406 12.5859 8.89844ZM13.5 11.5C14.7422 11.5 15.7969 11.9453 16.6641 12.8359C17.5547 13.7031 18 14.7578 18 16V16.5625C18 17.0312 17.8359 17.4297 17.5078 17.7578C17.1797 18.0859 16.7812 18.25 16.3125 18.25H1.6875C1.21875 18.25 0.820312 18.0859 0.492188 17.7578C0.164063 17.4297 0 17.0312 0 16.5625V16C0 14.7578 0.433594 13.7031 1.30078 12.8359C2.19141 11.9453 3.25781 11.5 4.5 11.5H6.43359C7.25391 11.875 8.10938 12.0625 9 12.0625C9.89062 12.0625 10.7461 11.875 11.5664 11.5H13.5Z" fill="#718098" />
          </svg>
        </a>
        <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
          <!--<a class="dropdown-item" href="#profile"><i class="fa fa-user"></i> Profile</a>-->
          <a class="dropdown-item" *ngIf="isLoaded"><i class="fa fa-user"></i>{{loggedInuser}}</a>
          <a class="dropdown-item" *ngIf="isLoaded"  (click)="changePassword()"><i class="fa fa-key"></i>Change Password</a>
          <a class="dropdown-item" (click)="logOutUser()" routerLink="/login"><i class="fa fa-lock"></i> Logout</a>
        </div>
      </li>
    </ul>
  </nav>
	</header>
