/**
 * Created by Harish Chandra.
 */
import { Injectable } from '@angular/core'
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import { Observable } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr'

@Injectable()
export class InterceptService implements HttpInterceptor {
  constructor(private router: Router,private toastr:ToastrService) {}

  // intercept request and add token
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url == 'http://uatadmin.garudauav.com/services/api/auth') {
      request = request
    }
    // modify request
    else if (request.url.includes('s3.ap-south-1.amazonaws.com')) {
      request = request
    } else {
      request = request.clone({
        setHeaders: {
          Authorization: `bearer ${localStorage.getItem('token')}`,
        },
      })
    }

    return next.handle(request).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            // http response status code
            console.log(event.status)
          }
        },
        (error) => {
          if (error.status == 401) {
            this.router.navigate(['/login'])
          }
          if (error.status == 0) {
             if(request.url==="https://ai.bluehawk.ai:8000/ai/thermal_image/temp"){
              this.toastr.error("AI Server is Down");
             }
           
          }
          // if (error.status == 401) {
          //   this.router.navigate(['/login'])
          // }
        }
      )
    )
  }
}
