import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgxSpinnerModule } from 'ngx-spinner'
import { HttpModule } from '@angular/http'
import { ChartsModule } from 'ng2-charts'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { EventsModule } from 'angular4-events';
import { ServiceWorkerModule } from '@angular/service-worker'
import { environment } from '../environments/environment'
import { ActivateGuard } from './guard/activate-guard'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { InterceptService } from './http-interceptors/http-interceptor.service'
import { ToastrModule } from 'ngx-toastr'
import { HeaderComponent } from './components/common/header/header.component'
import { FooterComponent } from './components/common/footer/footer.component'
import { LeftPanelComponent } from './components/common/left-panel/left-panel.component'
import { DefaultLayoutComponent } from './components/common/default-layout/default-layout.component'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { ViewOrganizationComponent } from './modules/organization/view-organization/view-organization.component'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { MatDialogModule } from '@angular/material/dialog'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { DndDirective } from './shared/directives/draganddrop.directive'
import { LocationStrategy, HashLocationStrategy } from '@angular/common'
import { MatExpansionModule } from '@angular/material/expansion'
import { MaterialModule } from './modules/material.module'
import {MatBadgeModule} from '@angular/material/badge';
import { ChangePasswordComponent } from './components/common/change-password/change-password.component'
import { ResetPasswordComponent } from './components/common/reset-password/reset-password.component'
const APP_CONTAINERS = [DefaultLayoutComponent]


@NgModule({
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    HeaderComponent,
    FooterComponent,
    LeftPanelComponent,
    DndDirective,
    ChangePasswordComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    HttpClientModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    NgxSpinnerModule,
    BsDropdownModule.forRoot(),
    NgbModule,
    HttpModule,
    MatDialogModule,
    FormsModule,
    ChartsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MaterialModule,
    MatBadgeModule,
    EventsModule.forRoot()
  ],
  providers: [
    ActivateGuard,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports:[ChangePasswordComponent,ResetPasswordComponent]
})
export class AppModule {}
