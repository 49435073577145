import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {
  transform(items: any[], searchText: string, fromPage: any): any[] {
    if (!items) {
      return []
    }
    if (!searchText) {
      return items
    }
    searchText = searchText.toLocaleLowerCase()

    return items.filter((it) => {
      if (fromPage == 'proj') {
        return it.Name.toLocaleLowerCase().includes(searchText)
      }
      if (fromPage == 'ins') {
        return it.FileName.toLocaleLowerCase().includes(searchText)
      }
      if (fromPage == 'user') {
        return it.FullName.toLocaleLowerCase().includes(searchText)
      }
      if (fromPage == 'org') {
        return it.Name.toLocaleLowerCase().includes(searchText)
      }
      if (fromPage == 'master') {
        return it.MasterLookupName.toLocaleLowerCase().includes(searchText)
      }
    })
  }
}
